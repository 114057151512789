/**
 * @file ReadSpeaker-Konfiguration
 * @author <a href="mailto:heiko.pfefferkorn@ifabrik.de">Heiko Pfefferkorn</a>
 * @copyright i-fabrik GmbH 2019
 */

/* global window:true */

window.rsConf = {
	general : {
		cookieName           : 'SportlandBildungReadSpeaker',
		// 'usePost'   : true,
		useCompactPopupButton: 1,
		popupCloseTime       : 2000
	},
	phrases : {
		de_de: {
			loaderror: 'Fehler'
		},
		en   : {
			loaderror: 'Error'
		}
	},
	settings: {
		'hlicon'  : 'iconon',
		'hlscroll': 'scrolloff'
	},
	ui      : {
		tools: {
			// 'enlarge'      : false,
			// 'help'         : false,
			pagemask : false,
			readhover: false
			// 'simpleview'   : false,
			// 'voicesettings': false
		}
	// 'disableDetachment': true,
	// 'enlarge'          : false,
	// readhover: true,
	// 'rsbtnClass'       : 'rspeak-custom__btn',
	// tools: {
	// 'enlarge'      : false,
	// 'help'         : false,
	// pagemask : false,
	// readhover: true
	// 'simpleview'   : false,
	// 'voicesettings': false
	// }
	},
	cb: {
		ui: {
			open: function() {
				console.log('foooo');
			}
		}
	}
};
